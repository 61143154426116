import clsx from 'clsx';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { find } from 'lodash';
import * as React from 'react';
import Cover from '../../components/Cover/Cover';
import Li from '../../components/Li/Li';
import { Seo } from '../../components/Seo';
import { OutboundLink } from '../../components/UI/OutboundLink/OutboundLink';
import Layout from '../../layouts/layout';
import * as classes from './features.module.scss';

const SecurityPage = props => {
    const { t } = useI18next();

    return <Layout location={props.location}>
        <Cover
            title={<Trans i18nKey="security-page:pageTitle">Security and Certifications</Trans>}
            subtitle={<Trans i18nKey="security-page:pageSubtitle">Secure your digital assets, ensure data protection,
                                                                  compliance, and accessibility with AIDA</Trans>}
            cover="security"
        />
        <div className="container p-0 intro">
            <Trans i18nKey="security-page:pageDescription">
                <p>In an increasingly digitized world, the protection of information and compliance with regulations
                   become <strong>essential</strong> aspects for businesses of any size.</p>
                <p>Our goal is to provide you with a reliable and secure solution for the <strong>storage, retrieval,
                                                                                                  and
                                                                                                  sharing</strong> of
                   your documents in the cloud, while ensuring maximum privacy and adherence to current regulations.</p>
                <p>AIDA is designed to offer an intuitive and high-performing user experience, without compromising data
                   security.
                   Thanks to the use of the most advanced <strong>encryption technologies</strong>, a
                   dependable <strong>cloud infrastructure</strong>, and strict <strong>compliance policies</strong>, we
                   can guarantee that your information is always protected and accessible only to authorized
                   individuals.</p>
            </Trans>
        </div>
        <div className={classes.FeaturesContainer}>
            <div className={classes.Features}>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage
                            src="../../images/security-certifications/aida-security-certifications-gdpr-hipaa.jpg"
                            width={901}
                            height={500}
                            alt="AIDA - GDPR - HIPAA"
                        />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('security-page:pageTitle')}</div>
                            <h2 className="title"><Trans>Certifications</Trans></h2>
                            <Trans i18nKey="security-page:certifications">
                                <p>AIDA environment is <OutboundLink href="https://www.hhs.gov/hipaa/index.html"
                                                                     target="_blank"
                                                                     rel="noopener">HIPAA</OutboundLink> compliant and
                                   adheres to
                                   the&nbsp;<OutboundLink
                                        href="https://ec.europa.eu/info/law/law-topic/data-protection/eu-data-protection-rules_en"
                                        target="_blank" rel="noopener">GDPR</OutboundLink> principles.</p>
                                <p>The Health Insurance Portability and Accountability Act (HIPAA) is a United States
                                   federal law
                                   that defines the requirements for handling personal protected health data:
                                   this ensures that every information in AIDA is treated with the most elevated level
                                   of
                                   privacy.</p>
                                <p>I dati degli utenti europei sono trattati all'interno dell'Unione Europea,
                                   specificamente all'interno dei datacenter di Francoforte ed Irlanda.</p>
                                <p>If you have any questions related to our privacy policy and compliances, please
                                   contact our Data
                                   Protection Officer: privacy@tclab.it.</p>
                            </Trans>

                        </div>
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-1 order-md-0', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('security-page:pageTitle')}</div>
                            <h2 className="title"><Trans>Infrastructure</Trans></h2>
                            <Trans i18nKey="security-page:infrastructure">
                                <p>AIDA is hosted entirely in AWS’s cloud.</p>
                                <p>Each piece of infrastructure is inherently redundant and always available, or made it
                                   so by
                                   using both horizontal scaling and continuous monitoring with automatic recovery
                                   routines.</p>
                                <p>AIDA's core computational resources can scale horizontally to better adapt to
                                   incoming
                                   workloads. Infrastructure is located across AWS's regions of Ireland, London (UK),
                                   Frankfurt
                                   (Germany) and North Virginia (US).</p>
                                <p>AIDA browser application (www.aidacloud.com) is deployed through a CDN network
                                   provided
                                   by AWS to guarantee the fastest access time and unlimited scaling throughout the
                                   world.</p>
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('col-md-6 order-0 order-md-1', classes.Picture)}>
                        <StaticImage
                            src="../../images/security-certifications/aida-security-certifications-aws.jpg"
                            width={901}
                            height={500}
                            alt="AIDA - Powered by AWS"
                        />
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage
                            src="../../images/security-certifications/aida-security-certifications-security.jpg"
                            width={901}
                            height={610}
                            alt="AIDA - encryption and security"
                        />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('security-page:pageTitle')}</div>
                            <h2 className="title"><Trans>Encryption</Trans></h2>
                            <h3><Trans>Data encryption</Trans></h3>
                            <Trans i18nKey="security-page:encryption">
                                <Li><strong>In transit (end-to-end: from user's endpoint to AIDA)</strong>: the
                                                                                                          connection
                                                                                                          from the
                                                                                                          user's
                                                                                                          endpoint is
                                                                                                          always
                                                                                                          encrypted by
                                                                                                          using HTTPs
                                                                                                          with&nbsp;
                                    <OutboundLink href="https://en.wikipedia.org/wiki/Transport_Layer_Security#TLS_1.3"
                                                  target="_blank" rel="noopener">TLS 1.3</OutboundLink>&nbsp;(including
                                    &nbsp;<OutboundLink
                                        href="https://en.wikipedia.org/wiki/HTTP_Strict_Transport_Security"
                                        target="_blank" rel="noopener">HSTS</OutboundLink>)</Li>
                                <Li><strong>In transit (inside AWS network)</strong>: connection between AIDA’s cloud
                                                                                    resources is
                                                                                    always encrypted with HTTPs or
                                                                                    similar
                                                                                    technologies, and user’s data never
                                                                                    leaves AWS
                                                                                    own data centers.
                                                                                    This includes, but it's not limited,
                                                                                    to
                                                                                    connections between AIDA and the
                                                                                    databases
                                                                                    needed for processing.</Li>
                                <Li><strong>At rest</strong>: all data at rest, ie all data that is stored in AIDA until
                                                            the user
                                                            decides to delete it or it's no longer needed for processing
                                                            (whichever
                                                            comes first), is securely stored and encrypted with
                                    &nbsp;<OutboundLink
                                        href="https://en.wikipedia.org/wiki/Advanced_Encryption_Standard"
                                        target="_blank" rel="noopener">AES 256 keys</OutboundLink>.</Li>
                            </Trans>
                            <h3 style={{ marginTop: '20px' }}><Trans>User account passwords</Trans></h3>
                            <Trans i18nKey="security-page:passwords">
                                All user passwords are saved with a strong unidirectional hashing algorithm that is
                                industry
                                standard and will be updated as new standards emerge.
                            </Trans>
                        </div>
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-1 order-md-0', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('security-page:pageTitle')}</div>
                            <h2 className="title"><Trans>Audit and monitoring</Trans></h2>
                            <Trans i18nKey="security-page:audit">
                                <p>We've set in place automatic notifications that are sent to our engineers as soon as
                                   a
                                   problem or an error is detected, so they can quickly identify and proactively fix
                                   them.</p>
                                <p>We log key metrics of the infrastructure performances in order to enable us to
                                   proactively
                                   intervene whenever anomalies may occur.</p>
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('col-md-6 order-0 order-md-1', classes.Picture)}>
                        <StaticImage
                            src="../../images/security-certifications/aida-security-certifications-monitoring.jpg"
                            width={901}
                            height={500}
                            alt="AIDA - Automatic monitoring"
                        />
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage
                            src="../../images/security-certifications/aida-security-certifications-coding.jpg"
                            width={901}
                            height={480}
                            alt="AIDA - Coding best practices"
                            quality="90"
                        />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('security-page:pageTitle')}</div>
                            <h2 className="title"><Trans>Coding best practices and Continuous Deployment</Trans></h2>
                            <Trans i18nKey="security-page:coding">
                                <p>We follow coding best practices such as <OutboundLink
                                    href="https://owasp.org/www-pdf-archive/OWASP_SCP_Quick_Reference_Guide_v2.pdf"
                                    target="_blank"
                                    rel="noopener">OWASP Secure Coding Practices</OutboundLink> to ensure code
                                   quality and ease of maintenance.
                                   Each time the code changes, it undergoes both automatic and manual review processes,
                                   in
                                   order to make sure it can be deployed safely onto production.</p>
                                <p>We also follow <OutboundLink
                                    href="https://en.wikipedia.org/wiki/Test-driven_development"
                                    target="_blank" rel="noopener">TDD (Test-driven
                                                                   development)
                                                                   principles</OutboundLink>,
                                   such as unit testing, integration
                                   testing, and automatic UI tests.</p>
                                <p>We strongly believe in agile development and we have developed techniques that enable
                                   us to
                                   continuously update the software in production without disrupting user's operations
                                   (<OutboundLink href="https://en.wikipedia.org/wiki/Continuous_deployment"
                                                  target="_blank"
                                                  rel="noopener">Continuous Deployment</OutboundLink>)</p>
                            </Trans>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>;
};

export default SecurityPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
        filter: {ns: {in: ["common", "security-page"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
    const edge = find(data.locales.edges, [ 'node.ns', 'security-page' ]);
    let obj = undefined;
    if (!!edge) {
        obj = JSON.parse(edge.node.data);
    }
    const title = !!obj ? obj.title : 'Security and Certifications';

    return <Seo title={title} description={!!obj ? obj.description : null} />;
};

