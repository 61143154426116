import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import * as classes from './Li.module.scss';

const Li = ({ children }) => {
    return <div className={classes.Li}>
        <FontAwesomeIcon icon={'circle'} />
        {children}
    </div>;
};

export default Li;
